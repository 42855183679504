import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

import leftArrow from "../assets/leftArrow.png";
import rightArrow from "../assets/rightArrow.png";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the OP Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'LAWRENCE BISHNOI',
    status : 'BODMASH'
  },
  {
    image: image2,
    review: 'I made the right choice by choosing OP Fit Club but after getting to know that Bishnoi is also trained from here .Koi bachalo mujhe ',
    name: 'SELMON BHOI',
    status: 'HEAVY DRIVER'
  },
  {
    image : image3,
    review:'I am regretting why I did not choose the OP Fit Club earlier , if I had choosed that I would have been alive today. ',
    name: 'SIDHU MOOSE WALA',
    status: "Artist"
  }
];
